<template>
    <CoreContainer>
        <BaseContainerContent tag="nav" class="vut-customer-nav">
            <ul class="flex flex-col md:flex-row">
                <li v-for="link in items"
                    :key="link.routeName"
                    class="vut-customer-nav__item"
                    :class="{'flex-1': type === 'fill'}"
                >
                    <component
                        :is="!link.disabled && localePath(link.routeName) ? NuxtLink : 'div'"
                        :to="localePath(link.routeName)"
                        class="vut-customer-nav__item-link"
                        :class="{'vut-customer-nav__item-link--disabled': link.disabled}"
                        active-class="vut-customer-nav__item-link--active"
                    >
                        {{ $t(link.labelPath) }}
                    </component>
                </li>
            </ul>
        </BaseContainerContent>
    </CoreContainer>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'

const {
    items,
    itemClass,
    type,
} = defineProps<{
    items: {
        labelPath: string
        routeName: string
        disabled?: boolean
    }[]
    itemClass?: string
    type?: 'fill' | undefined
}>()

const localePath = useLocalePath()
</script>

<style lang="scss" scoped>
.vut-customer-nav {
    background-image: radial-gradient($vut-c-white 2px, $vut-c-ivory 0);
    background-size: 12px 12px;
}

.vut-customer-nav__item {
    border: hr-line();

    &:not(:last-child) {
        border-bottom: none;

        @include more-than(md) {
            border-right: none;
            border-bottom: hr-line();
        }
    }
}

.vut-customer-nav__item-link {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    padding: 1rem;

    height: 100%;

    background: $vut-c-white;

    text-transform: uppercase;
    font-family: $vut-ff-secondary;
    letter-spacing: $vut-letter-spacing-large;

    transition: color $vut-trans-time $vut-timing, background $vut-trans-time $vut-timing;

    &:hover:not(.vut-customer-nav__item-link--disabled) {
        background: $vut-c-secondary;
        color: $vut-c-white;
    }

    &:focus-visible {
        z-index: 1;
    }

    @include more-than(lg) {
        padding: 1.25rem 2.125rem;
    }
}

.vut-customer-nav__item-link--active {
    background: $vut-c-secondary;
    color: $vut-c-white;

    cursor: not-allowed;
}

.vut-customer-nav__item-link--disabled {
    cursor: not-allowed;
}
</style>
